import React, { useState } from 'react';

function App() {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [interest_amount, setResult1] = useState('');
  const [monthly_payment, setResult2] = useState('');

  const handleSubmit = async () => {
    // Get the input values
    const euribor = input1;  // Assuming input1 stores the euribor value
    const margin = input2;   // Assuming input2 stores the margin value
    const loanDuration = input3;  // Assuming input3 stores the loan duration value
  
    // Construct the URL with query parameters
    const url = `https://sample-749682801995.us-west1.run.app/calculate?euribor=${euribor}&margin=${margin}&loan_duration=${loanDuration}`;
  
    try {
      // Make a GET request to the API
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      
      // Parse the JSON response
      const data = await response.json();
  
      // Update the state with the received data
      setResult1(data.interest_amount);
      setResult2(data.monthly_payment);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Loan calculator</h2>
      <div>
        <input
          type="text"
          placeholder="Euribor"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Margin"
          value={input2}
          onChange={(e) => setInput2(e.target.value)}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Loan duration"
          value={input3}
          onChange={(e) => setInput3(e.target.value)}
        />
      </div>
      <button onClick={handleSubmit}>Submit</button>
      <div>
        <h3>Result:</h3>
        <p>Interest amount: {interest_amount}</p>
        <p>Monthly payment: {monthly_payment}</p>
      </div>
    </div>
  );
}

export default App;